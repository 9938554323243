import "./test.css";

import ServiceCard from "../RLD_Services/ServiceCard";

import previz from "../../images/ServicePhotos/previz.jpg";

function Test() {
  return (
    <div className="bg-neutral-900 flex flex-col justify-center items-center  min-h-[95vh] ">
      <div className="container flex flex-col justify-center items-start">
        <div className="m-4 mt-20">
          <h2 className="text-4xl font-extrabold">Here's What</h2>
          <h2 className="text-3xl font-bold text-green-50">
            We can do for you.
          </h2>
        </div>
        <div className="rows">
          {/* Row 1 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between">
            {/* card 1 */}
            <ServiceCard
              className=""
              service={["Festival, Tour, &", <br />, "Stage Design"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={["Vectorworks, Plots, &", <br />, "Technical Paperwork"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={["Stage Concepts", <br />, "& Renders"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
          </div>
          {/* Row 2 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between">
            {/* card 1 */}
            <ServiceCard
              className=""
              service={["Festival, Tour, &", <br />, "Stage Design"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={["Vectorworks, Plots, &", <br />, "Technical Paperwork"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={["Stage Concepts", <br />, "& Renders"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
          </div>
          {/* Row 3 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between pb-20">
            {/* card 1 */}
            <ServiceCard
              className=""
              service={["Festival, Tour, &", <br />, "Stage Design"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={["Vectorworks, Plots, &", <br />, "Technical Paperwork"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={["Stage Concepts", <br />, "& Renders"]}
              photo={previz}
              alt_info={"This is a photo"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
